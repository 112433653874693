import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPage } from '../Page';
import Selectors from '../Selectors';
import ActionCreator from '../ActionCreator';
import * as L from '../Utils/Lang';
import ItemInCart from '../Components/ItemInCart';
import * as Widget from '../Components/Widget';
import Stepper from '../Components/Stepper';
import PathPrefix from '../Utils/PathPrefixUtil';

class CartPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
    };

    // special property to deal with item config change
    this.dirtyMap = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.cartData !== this.props.cartData) {
      this.dirtyMap = {};
      this.forceUpdate();
    }
  }

  render() {
    let { showSpinner } = this.state;
    let { profile, cartData, navActions } = this.props;

    if (!profile) {
      return <Wrapper>{L.s(`please-login-first`)}</Wrapper>;
    }

    let isEmpty = Object.keys(cartData.items).length === 0;
    let isDirty = Object.keys(this.dirtyMap).length > 0;

    return (
      <Wrapper>
        <Stepper
          extraCss="margin: 0 auto 30px;"
          currentStep={Stepper.STEP_STATE.CART}
        />

        {isEmpty ? (
          <Widget.Center style={{ padding: 40 }}>
            <div style={{ width: 131, height: 131 }}>
              <Widget.BgImage
                extraCss="background-color: inherit;"
                size="contain"
                image={PathPrefix(`/images/order_record/icon-empty-box.png`)}
              />
            </div>
            <div style={{ color: '#A1A1A1', fontSize: 20, marginTop: 26 }}>
              {L.s(`cart-empty`)}
            </div>
          </Widget.Center>
        ) : (
          <div className="content">
            <h2 className="title">購物車</h2>

            <div className="card">
              <Cart>
                <div className="cart-header">
                  <div className="cart-header-field">{L.s('product-info')}</div>

                  <div className="cart-header-field">{L.s('data')}</div>

                  <div className="cart-header-field">{L.s('days')}</div>

                  <div className="cart-header-field">{L.s('quantity')}</div>

                  <div className="cart-header-field">{L.s('subtotal')}</div>
                </div>
                {this._renderAllItemsInCart()}
              </Cart>
            </div>

            <h2 className="title">訂單資訊</h2>

            <div className="card center">
              <OrderInfo className={`${isDirty ? 'line-through' : ''}`}>
                <div className="orderInfoItem">
                  <div>{L.s('products-total')}</div>
                  <div className="price">{`NT$${cartData.price - cartData.shipping_fee}`}</div>
                </div>
                <div className="separator" />
                <div className="orderInfoItem total">
                  <div>{L.s(`total`)}</div>
                  <div className="price">{`NT$${cartData.price - cartData.shipping_fee}`}</div>
                </div>
              </OrderInfo>
            </div>

            {showSpinner ? (
              <Widget.Spinner style={{ alignSelf: 'flex-end' }} />
            ) : (
              <Widget.BlueButton
                extraCss="align-self: flex-end;"
                onClick={() => {
                  this._showSpinner(1000).then(() =>
                    navActions.push('/checkout')
                  );
                }}
                disabled={isDirty}
              >
                {L.s(`next`)}
              </Widget.BlueButton>
            )}
          </div>
        )}
      </Wrapper>
    );
  }

  _renderAllItemsInCart = () => {
    let { cartData } = this.props;
    let configs = [];
    for (let itemId in cartData.items) {
      let item = cartData.items[itemId];
      configs = [
        ...configs,
        ...item.configs.map(c => (
          <ItemInCart
            key={c.tid}
            itemId={item.id}
            config={c}
            onConfigInvalidated={dirty => {
              if (dirty) {
                this.dirtyMap[c.tid] = true;
              } else {
                delete this.dirtyMap[c.tid];
              }

              this.forceUpdate();
            }}
            display="cart"
          />
        )),
      ];
    }

    return <div>{configs}</div>;
  };

  _showSpinner = ms => {
    this.setState({ showSpinner: true });
    return new Promise(resolve => {
      setTimeout(() => {
        this.setState({ showSpinner: false });
        resolve();
      }, ms);
    });
  };
}

const Wrapper = styled.div`
  flex-grow: 1;
  padding: 30px 20px 50px;
  background: #f6f6f6;

  & > .content {
    max-width: 940px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    & > .title {
      margin-bottom: 10px;
      color: #3f8dcf;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
    }

    & > .card {
      margin-bottom: 30px;
      border-radius: 10px;
      background: #ffffff;
      box-shadow: 0 1px 10px 0 #dfdfdf;
    }

    & > .center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const Cart = styled.div`
  padding: 30px 0;
  color: #343434;
  font-size: 16px;

  & > .cart-header {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 40px;
    display: flex;

    & > .cart-header-field {
      width: 25%;
      margin-right: 20px;
    }

    & > .cart-header-field:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 600px) {
    & > .cart-header {
      display: none;
    }
  }
`;

const OrderInfo = styled.div`
  width: 100%;
  max-width: 420px;
  padding: 30px 20px 35px;
  color: #343434;
  font-size: 16px;
  line-height: 30px;

  & > .orderInfoItem {
    display: flex;
    justify-content: space-between;
  }

  & > .total {
    font-weight: 600;
  }

  & > .separator {
    height: 1px;
    margin: 10px 0;
    background: #e5e5e5;
  }

  &.line-through > .orderInfoItem > .price {
    text-decoration: line-through;
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      cartData: Selectors.cart.getData(state),
    }),
    ActionCreator
  )(CartPage)
);
