import React from 'react';
import styled from 'styled-components';

class Stepper extends React.Component {
  static STEP_STATE = {
    CART: 'CART',
    CHECKOUT: 'CHECKOUT',
    ORDER: 'ORDER',
  };

  static steps = [
    { key: Stepper.STEP_STATE.CART, title: '購物車', stepNumber: 1 },
    { key: Stepper.STEP_STATE.CHECKOUT, title: '填寫資料', stepNumber: 2 },
    { key: Stepper.STEP_STATE.ORDER, title: '確認訂單', stepNumber: 3 },
  ];

  render() {
    let { extraCss } = this.props;

    return (
      <Wrapper extraCss={extraCss}>
        {Stepper.steps.map((s, idx) => (
          <StepItem
            key={s.title}
            isFirst={idx === 0}
            isActive={this._getActiveStatus(s.key)}
            stepNumber={s.stepNumber}
            title={s.title}
          />
        ))}
      </Wrapper>
    );
  }

  _getActiveStatus = step => {
    let { currentStep } = this.props;
    let itemIndex = Stepper.steps.findIndex(s => s.key === step);
    let currentStepIndex = Stepper.steps.findIndex(s => s.key === currentStep);

    return itemIndex <= currentStepIndex;
  };
}

const Wrapper = styled.div`
  width: 420px;
  max-width: 100%;
  display: flex;

  ${props => props.extraCss};
`;

class StepItem extends React.Component {
  render() {
    let { extraCss, isFirst, isActive, stepNumber, title } = this.props;

    return (
      <StepItem.Wrapper
        extraCss={extraCss}
        isFirst={isFirst}
        isActive={isActive}
      >
        <div className="circle">{stepNumber}</div>
        <div className="title">{title}</div>
      </StepItem.Wrapper>
    );
  }

  static Wrapper = styled.div`
    position: relative;
    flex-grow: 1;
    flex-basis: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before {
      content: '';
      display: ${props => (props.isFirst ? 'none' : 'block')};
      position: absolute;
      top: 20px;
      right: 50%;
      width: 100%;
      height: 3px;
      background: ${props => (props.isActive ? '#3F8DCF' : '#E7E7E7')};
    }

    & > .circle {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: ${props => (props.isActive ? '#3F8DCF' : '#E7E7E7')};
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 20px;
      font-weight: 600;
      z-index: 1;
    }

    & > .title {
      color: #343434;
      font-size: 16px;
    }

    ${props => props.extraCss};
  `;
}

export default Stepper;
